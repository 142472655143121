<template>
  <main>
    <!-- Page header -->
    <div class="d-flex flex-column-reverse flex-md-column mb-3">
      <div class="bg-light py-2">
        <div class="container-fluid">
          <div class="d-md-flex align-items-end justify-content-between">
            <div class="d-flex justify-content-between">
              <div>
                <h1 class="page-title">Edit Application</h1>
                <h3 class="page-title-sub mb-1">
                  <a href="javascript:void(0)">Package Title</a>
                </h3>
                <ul class="list-inline mb-md-1">
                  <li class="list-inline-item">
                    <span class="text-muted">Type: <strong>Land Title</strong></span>
                  </li>
                </ul>
              </div>

              <!-- Manage package dropdown mobile -->
              <div class="d-md-none">
                <button
                  class="btn btn-md btn-link pr-0 pl-lg-2 dropdown-toggle"
                  id="managePackage"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <svg class="icon icon-settings">
                    <use xlink:href="/icons/symbol-defs.svg#icon-settings"></use>
                  </svg>
                </button>
                <div class="dropdown-menu text-right" aria-labelledby="managePackage">
                  <a class="dropdown-item" href="javascript:void(0)">Download Package</a>
                  <a class="dropdown-item" href="javascript:void(0)">Delete Package</a>
                </div>
              </div>
            </div>

            <ul class="nav mt-3-md mt-lg-1 d-print-none">
              <li class="nav-item d-none d-md-block">
                <button
                  class="btn btn-md btn-link pl-0 pl-lg-2 pr-2 dropdown-toggle"
                  id="managePackage"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Manage Package
                </button>
                <div class="dropdown-menu" aria-labelledby="managePackage">
                  <a class="dropdown-item" href="javascript:void(0)">Download Package</a>
                  <a class="dropdown-item" href="javascript:void(0)">Delete Package</a>
                </div>
              </li>
              <li class="nav-item mr-1">
                <a class="btn btn-md btn-secondary px-2" href="javascript:void(0)">E-sign</a>
              </li>
              <li class="nav-item">
                <a class="btn btn-md btn-secondary px-2" href="javascript:void(0)">
                  Prepare Submission
                  <svg class="icon icon-arrow_cta">
                    <use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta"></use>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="container-fluid bg-md-dark border-md-bottom py-1 py-md-2 border-bottom">
        <div class="d-flex justify-content-between justify-content-md-start align-items-center">
          <!-- Back to applications link -->
          <router-link class="mr-2 text-md-white" to="/pages/applications">
            <svg class="icon icon-arrow_cta_back mr-1">
              <use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta_back"></use>
            </svg>
            <span class="d-none d-sm-inline-block">Return to Applications</span>
            <span class="d-sm-none d-inline-block">Back</span>
          </router-link>

          <!-- Change instrument dropdown -->
          <a
            href="javascript:void(0)"
            class="btn btn-md btn-secondary dropdown-toggle d-sm-inline-block btn-md-white"
            id="instrument"
            data-toggle="dropdown"
            aria-controls="instrument-contents"
            aria-expanded="false"
          >
            Go To Application
            <span class="caret"></span>
          </a>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" href="javascript:void(0)">01 - Application name</a>
            <a class="dropdown-item" href="javascript:void(0)">02 - Application name</a>
          </div>
        </div>
      </div>
    </div>

    <!-- Page content -->
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <!-- Package title and actions -->
          <div class="row flex-md-nowrap">
            <div class="w-50 break-word col mr-auto">
              <span class="page-title h2 mr-1">Application Title</span>
              <a href="javascript:void(0)">
                <svg class="icon icon-edit">
                  <use xlink:href="/icons/symbol-defs.svg#icon-edit"></use>
                </svg>
              </a>

              <ul class="list-inline mb-1">
                <li class="list-inline-item">
                  <small><span class="text-muted text-small">Status:</span> <strong>Drafted</strong></small>
                </li>
                <li class="list-inline-item">
                  <small><span class="text-muted">Modified:</span> <strong>Oct 08 2020 10:17 AM</strong></small>
                </li>
              </ul>
            </div>

            <div class="col-md-auto">
              <div class="d-flex justify-content-between">
                <!-- Navigate application mobile  -->
                <button
                  aria-controls="application-nav"
                  aria-expanded="false"
                  class="btn btn-md btn-secondary mr-auto d-md-none mb-1"
                  data-target="#application-nav"
                  data-toggle="collapse"
                  type="button"
                >
                  <svg class="icon icon-menu">
                    <use xlink:href="/icons/symbol-defs.svg#icon-menu"></use>
                  </svg>
                  <svg class="icon icon-close">
                    <use xlink:href="/icons/symbol-defs.svg#icon-close"></use>
                  </svg>
                  Navigate Application
                </button>

                <button aria-controls="instrument-contents" aria-expanded="false" class="btn btn-md btn-secondary dropdown-toggle mb-1" data-toggle="dropdown">
                  <svg class="icon icon-settings d-sm-none">
                    <use xlink:href="/icons/symbol-defs.svg#icon-settings"></use>
                  </svg>
                  Manage <span class="d-none d-sm-inline-block">Template</span>
                  <span class="caret"></span>
                </button>
                <div aria-labelledby="dropdownMenuButton" class="dropdown-menu">
                  <a class="dropdown-item" href="#">Save Template</a>
                  <a class="dropdown-item" href="#">Load Template</a>
                  <a class="dropdown-item text-danger d-md-none" href="#">Delete Application</a>
                </div>

                <button class="btn btn-md btn-danger mb-1 d-none d-sm-block ml-1" type="button">
                  <svg class="icon icon-delete d-md-none">
                    <use xlink:href="/icons/symbol-defs.svg#icon-delete"></use>
                  </svg>
                  <span class="d-none d-md-inline-block">Delete Application</span>
                </button>
              </div>
            </div>
          </div>

          <!-- Main package content -->
          <div class="row mt-md-3">
            <!-- Sidebar navigation -->
            <div class="col-md-3 mb-2 mb-md-4">
              <div class="collapse collapse-md" id="application-nav">
                <div class="step-wizard">
                  <div class="step active">
                    <a class="step-link" href="javascript:void(0)">Active nav item title </a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Nav item title </a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Nav item title </a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Nav item title </a>
                  </div>
                </div>
              </div>
            </div>

            <!-- Main content / Data entry form -->
            <div class="col-md-9">
              <h2 class="mb-md-3">Entry Form Title</h2>

              <div class="mb-4 p-2 p-md-4 bg-medium" style="height:300px">
                <!-- Page content goes here. style tag just for demo purpose -->
              </div>

              <!-- Action buttons -->
              <div class="d-flex flex-column-reverse flex-sm-row justify-content-sm-between d-print-none">
                <div class="mt-2 mt-sm-0">
                  <button class="btn btn-secondary">Back</button>
                </div>
                <div class="d-flex justify-content-between">
                  <button class="btn btn-secondary mr-1">Save Progress</button>
                  <button class="btn btn-primary">
                    Continue
                    <svg class="icon icon-arrow_cta ml-1">
                      <use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta"></use>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Scroll to top -->
      <a href="#top" class="d-block float-right scroll-to-top">
        <svg class="icon icon-arrow_upward">
          <use xlink:href="/icons/symbol-defs.svg#icon-arrow_upward"></use></svg
        >Back to Top
      </a>
    </div>

    <help-panel title="Applications"></help-panel>
  </main>
</template>

<script>
export default {};
</script>

<style></style>
